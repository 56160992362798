import { styled } from 'styled-components';

export const FallbackSvg = styled('svg')`
  vertical-align: text-bottom;

  text {
    color: #fff;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;
